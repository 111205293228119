import {useCallback, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {get} from '../../../api';
import FormRenderer from '../../cmp/formbuilder/FormRenderer';
import StyledButton from '../../cmp/themed/StyledButton';
import {trimPostData} from '../../../tools/func';
import {FiTrash} from 'react-icons/fi';

export default function RenderForm({formId, formData, onSubmit}){
  const [fields, setFields] = useState([])
  const [values, setValues] = useState({})
  useEffect(()=>{
    if(formData && fields){
      const {customer_name, customer_email, data} = formData;
      const map = {
        _id: formData._id,
      };
      fields.forEach((i)=>{
        if(i.dataType === "name"){
          map[i.id] = customer_name
        }else if(i.dataType === "email"){
          map[i.id] = customer_email
        }else {
          map[i.id] = data[i.label]
        }
      })
      setValues(map)
    }else{
      setValues({})
    }
  },[fields, formData])
  const setData = useCallback((e)=>{
    setValues((v)=>{
      return {
        ...v,
        ...e
      }
    })

  },[])
  const [loading, setLoading] = useState(false)
  const [metadata, setMetadata] = useState(null)
  useEffect(()=>{
    setLoading(true)
    get('/form/'+formId).then((data)=>{
      setFields(data.fields)
      setMetadata(data.metadata);
      setLoading(false)
    })
    // get form fields for the form id
  },[formId])
  const [saving, setSaving] = useState(false)
  const [success, setSuccess] = useState(false)
  const submitLead = useCallback((e)=>{
    if(e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSaving(true)
    onSubmit(trimPostData(values)).then(()=>{
      setValues({

      })
      setSaving(false)
      setSuccess(true)
    })
  },[formId, onSubmit, values])

  return <>
    {
      loading ? <Skeleton height={36} count={5}/> : <form onSubmit={submitLead}>
        <FormRenderer onChange={setData} formFields={fields || []} value={values} />
        {
          success ? <p className={'alert alert-success'}>
            <>{(metadata ? metadata.successMessage : null) || 'Thank you for you submission'}</>
          </p> : null
        }
        <div className="d-flex align-items-center justify-content-between">
        <StyledButton loading={saving}
                onClick={submitLead} className={"btn btn-primary"} title={'Submit'} />
        {
          formData?._id ? <button className={"btn btn-link text-danger"}>
            <FiTrash />
          </button> : null
        }

        </div>
      </form>
    }
  </>
}
