import './styles/helpers.scss';
import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import store from './redux/store';
import {Provider} from 'react-redux';
import EmptyPage from './components/EmptyPage';
import {noop} from './config';
import {post} from './api';
import AutoLogin from "./components/pages/AutoLogin";
import LogoutPage from "./components/auth/components/LogoutPage";
import ZendeskRedirect from "./components/pages/integrations/ZendeskRedirect";
import Spinner from "./components/cmp/Spinner";

if(typeof window !== 'undefined'){
	const params = new URL(document.location.href).searchParams;
	// set access token if passed in URL. This is useful for membership plans
	// where if the portal is embedded in the page, the activation URL breaks out of the iframe
	// so we set the access token in the return URL to be picked up by the portal when the user returns
	const token = params.get('access_token') || params.get('gfpAffiliateToken')
	if(token){
		sessionStorage.setItem('access_token', token)
	}
}

if (process.env.NODE_ENV !== 'development' && new URL(document.location.href).searchParams.get('debug') !== 'true'){
	console.log = noop;
	console.warn = noop;
	console.error = noop;
}
const DevPage = React.lazy(()=>import('./pages/DevPage'))
function IntlApp(){
	return (
		<BrowserRouter>
			<Routes>
				<Route path={"/empty"} element={<EmptyPage/>} />
				<Route path={"/dev"} element={<DevPage/>} />
				<Route path={'/login-as/:access_token'} element={<AutoLogin/>}/>
				<Route path={'/logout'} element={<LogoutPage/>}/>
				<Route path={"/*"} element={<App/>} />
				<Route path={"/sso/zendesk"} element={<ZendeskRedirect />} />
				<Route path={"/sso/shopify"} element={<ShopifyMultipassRedirect />} />
			</Routes>
		</BrowserRouter>);
}

function ShopifyMultipassRedirect(){
	const return_to = new URL(document.location.href).searchParams.get('return_to')
	useEffect(() => {
		post('/extras/integrations/sso/shopify_multipass', {return_to}).then((res)=>{
			if (res.url){
				window.location.href = res.url+`${return_to ? `?return_to=${return_to}` : ''}`
			}
		})
	}, [return_to]);
	return<div className="my-4 text-center">
		<Spinner size={"lg"} />
	</div>
}

function doRender() {
	const container = document.getElementById('root');
	if(container) {
		const root = createRoot(container)
		root.render(
			// <ErrorBoundary>
			<Provider store={store}>
				<IntlApp/>
			</Provider>
//		</ErrorBoundary>
		);
	}
}
// require('intl');
doRender();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

window.addEventListener("message", (event) => {
	if (event.data){
//		console.log(event.data)
//		store.dispatch(event.data)
	}
}, false);
