import React, {useState} from 'react';
import {EyeClose, EyeOpen} from '../../../icons/Icons';

PasswordInput.defaultProps = {
  defaultType: 'password'
}
function PasswordInput({value, onChange, defaultType, id, name}) {
  const [type, setType] = useState(defaultType)
  return (
      <div className={"position-relative"}>
        <span onClick={()=>setType((t)=> t==="password" ? "text" : "password")} className={"text-muted position-absolute d-inline-flex align-items-center justify-content-center px-2"} style={{right:0, bottom:0, top:0}}>
          {
            type === "password" ? <EyeClose/> : <EyeOpen/>
          }
        </span>
        <input
            autoComplete={'current-password'}
            id={id} name={name}
            className={'form-control pr-4'} type={type} value={value} onChange={onChange}/>
      </div>
  );
}

export default PasswordInput;
